<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="type === 'tutorial'">{{ 'TUTORIAL_TITLE' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button class="close-button" fill="clear" (click)="dismiss(false)">
        <ion-icon slot="icon-only" color="danger" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content ion-padding-horizontal" #contentDiv>
  <ion-grid class="ion-text-center">
    <ng-container *ngIf="type !== 'tutorial'">
      <ion-row *ngIf="header">
        <ion-col>
          <h1>{{ header }}</h1>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="text">
        <ion-col>
          <p class="ion-text-center" style="white-space: pre-wrap;">{{ text }}</p>
        </ion-col>
      </ion-row>
    </ng-container>
    <!-- INPUT -->
    <ion-row *ngIf="type === 'input'">
      <ion-col>
        <div class="input-wrapper" (click)="input.setFocus()">
          <ion-input [(ngModel)]="data.value" autofocus="true" #input></ion-input>
        </div>
      </ion-col>
    </ion-row>
    <!-- STORY -->
    <ion-row *ngIf="type === 'story'">
      <ion-col>
        <p class="ion-text-center">
          <span *ngFor="let words of data.story" [class.selected-answers]="words.type === 'selected'">{{ words.value }}</span>
        </p>
      </ion-col>
    </ion-row>
    <!-- TUTORIAL -->
    <ion-row *ngIf="type === 'tutorial' && data" class="ion-align-items-center ion-justify-content-center">
      <ion-col [size]="data.video == 'Silly_Story' ? '8' : '12'">
        <video controls autoplay width="250" *ngIf="api.language && data.video">
      
          <source src="https://rily.games/videos/{{ api.language }}_{{ data.video }}.mp4"
                  type="video/mp4">
      
          Sorry, your browser doesn't support embedded videos.
        </video>
      </ion-col>
      <ion-col size="4" *ngIf="data.video && data.video == 'Silly_Story'">
        <div *ngIf="ranNum" class="extra-challenge" [style.transform]="'rotate(' + ranNum + 'deg)'">
          <ion-text color="title">
            <h2 style="font-weight: bold;">{{ 'STORY_BUILDER.EXTRA_TITLE' | translate }}</h2>
            <h3>{{ 'STORY_BUILDER.EXTRA_TEXT' | translate }}</h3>
          </ion-text>
        </div>
        <ion-button class="big-button" shape="round" size="small" (click)="dismiss(data)">
          <ng-container>{{ (button ? button : 'BUTTONS.OK') | translate }}</ng-container>    
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ion-footer *ngIf="!data || !data.video || (data.video && data.video != 'Silly_Story')">
  <ion-button class="big-button" shape="round" (click)="dismiss(data)" [size]="type === 'tutorial' ? 'small' : ''">
    <ng-container *ngIf="type === 'input'">{{ 'BUTTONS.SUBMIT' | translate }}</ng-container>
    <ng-container *ngIf="type !== 'input'">{{ (button ? button : 'BUTTONS.OK') | translate }}</ng-container>    
  </ion-button>
</ion-footer>

<!-- <div class="pyro" *ngIf="cssClasses && cssClasses.includes('correct')">
  <div class="before"></div>
  <div class="after"></div>
</div> -->
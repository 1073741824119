import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule) },
  { path: 'sentence-builder', loadChildren: './pages/sentence-builder/sentence-builder.module#SentenceBuilderPageModule' },
  { path: 'select-topic', loadChildren: './pages/select-topic/select-topic.module#SelectTopicPageModule' },
  { path: 'select-game', loadChildren: './pages/select-game/select-game.module#SelectGamePageModule' },
  { path: 'match-words', loadChildren: './pages/match-words/match-words.module#MatchWordsPageModule' },
  { path: 'missing-letter', loadChildren: './pages/missing-letter/missing-letter.module#MissingLetterPageModule' },
  { path: 'story-builder', loadChildren: './pages/story-builder/story-builder.module#StoryBuilderPageModule' },
  {
    path: 'custom-popup',
    loadChildren: () => import('./pages/custom-popup/custom-popup.module').then( m => m.CustomPopupPageModule)
  },
  {
    path: 'choose-level',
    loadChildren: () => import('./pages/choose-level/choose-level.module').then( m => m.ChooseLevelPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

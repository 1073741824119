import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./pages/home/home.module.ngfactory").then(function (m) { return m.HomePageModuleNgFactory; }); }, ɵ1 = function () { return import("./pages/custom-popup/custom-popup.module.ngfactory").then(function (m) { return m.CustomPopupPageModuleNgFactory; }); }, ɵ2 = function () { return import("./pages/choose-level/choose-level.module.ngfactory").then(function (m) { return m.ChooseLevelPageModuleNgFactory; }); };
var routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', loadChildren: ɵ0 },
    { path: 'sentence-builder', loadChildren: './pages/sentence-builder/sentence-builder.module#SentenceBuilderPageModule' },
    { path: 'select-topic', loadChildren: './pages/select-topic/select-topic.module#SelectTopicPageModule' },
    { path: 'select-game', loadChildren: './pages/select-game/select-game.module#SelectGamePageModule' },
    { path: 'match-words', loadChildren: './pages/match-words/match-words.module#MatchWordsPageModule' },
    { path: 'missing-letter', loadChildren: './pages/missing-letter/missing-letter.module#MissingLetterPageModule' },
    { path: 'story-builder', loadChildren: './pages/story-builder/story-builder.module#StoryBuilderPageModule' },
    {
        path: 'custom-popup',
        loadChildren: ɵ1
    },
    {
        path: 'choose-level',
        loadChildren: ɵ2
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };

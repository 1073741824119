import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/storage";
import * as i3 from "@ionic/angular";
// const baseUrl = 'http://192.168.1.103/rily';
// const baseUrl = '.';
var baseUrl = 'https://rily.games/api';
var apiKey = '?apiKey=I9tSdWOptw6DjAKaiBipvycq3XWoyVXqjaJ2593H';
var ApiService = /** @class */ (function () {
    function ApiService(http, storage, platform) {
        this.http = http;
        this.storage = storage;
        this.platform = platform;
        this.language = 'en';
        this.topic = null;
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        this.handleError = function (error) {
            console.error(error);
            return throwError(error.message);
        };
    }
    ApiService.prototype.extractData = function (res) {
        return res || {};
    };
    /**
     * TODO: download images
     * Offline functionality. Fallback to locallly stored values if no internet connection.
     * @param request
     * @returns
     */
    // fallbackGet(request: string): Promise<any> {
    //   return new Promise((resolve, reject) => {
    //     // 
    //     const fallback = async () => {
    //       if (this.platform.is('cordova')) {
    //         this.storage.get(request).then((res) => {
    //           res ? resolve(res) : fallback();
    //         }, reject).catch(reject);
    //       } else reject();
    //     };
    //     // 
    //     this.get(request).subscribe((res) => {
    //       if (res) {
    //         this.storage.set(request, res);
    //         resolve(res);
    //       } else fallback();
    //     }, fallback);
    //     // 
    //   });
    // }
    ApiService.prototype.get = function (request) {
        // const url = `${request}`;
        var url = baseUrl + "/" + request + apiKey + "&topic=" + this.topic + "&language=" + this.language;
        return this.http.get(url, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    };
    ApiService.prototype.post = function (request, data) {
        if (data === void 0) { data = {}; }
        var url = baseUrl + "/" + request;
        return this.http.post(url, data, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.Platform)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };

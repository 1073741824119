import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ModalController, IonInput } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.page.html',
  styleUrls: ['./custom-popup.page.scss'],
})
export class CustomPopupPage implements OnInit, AfterViewInit {

  @Input() type: string;
  @Input() header: string;
  @Input() text: string;
  @Input() button: string;
  @Input() data: any;
  @Input() public cssClasses?: string[];
  @ViewChild('input', {static: false}) input: IonInput;

  public ranNum: number;

  constructor(
    private modalCtrl: ModalController,
    public api: ApiService
  ) { }

  ngOnInit() {
    this.ranNum = Math.ceil(Math.random() * 6) * (Math.round(Math.random()) ? 1 : -1)
  }

  ngAfterViewInit() {
    if (this.type === 'input' && this.input) {
      setTimeout(() => {
        this.input.setFocus();
      }, 500);
    }
  }

  public dismiss(data) {
    this.modalCtrl.dismiss(data);
  }

}

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

// const baseUrl = 'http://192.168.1.103/rily';
// const baseUrl = '.';
const baseUrl = 'https://rily.games/api';
const apiKey = '?apiKey=I9tSdWOptw6DjAKaiBipvycq3XWoyVXqjaJ2593H';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  public language = 'en';
  public topic: string = null;

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private platform: Platform
  ) {}

  private extractData(res: Response) {
    return res || {};
  }

  /**
   * TODO: download images
   * Offline functionality. Fallback to locallly stored values if no internet connection.
   * @param request 
   * @returns 
   */
  // fallbackGet(request: string): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     // 
  //     const fallback = async () => {
  //       if (this.platform.is('cordova')) {
  //         this.storage.get(request).then((res) => {
  //           res ? resolve(res) : fallback();
  //         }, reject).catch(reject);
  //       } else reject();
  //     };
  //     // 
  //     this.get(request).subscribe((res) => {
  //       if (res) {
  //         this.storage.set(request, res);
  //         resolve(res);
  //       } else fallback();
  //     }, fallback);
  //     // 
  //   });
  // }

  get(request: string): Observable<any> {
    // const url = `${request}`;
    const url = `${baseUrl}/${request}${apiKey}&topic=${this.topic}&language=${this.language}`;
    return this.http.get(url, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  post(request: string, data = {}): Observable<any> {
    const url = `${baseUrl}/${request}`;
    return this.http.post(url, data, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    console.error(error);
    return throwError(error.message);
  }

}
